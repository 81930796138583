<template>
    <side-panel class="review-details d-flex">
        <div class="position-sticky title d-flex flex-row justify-content-between p-3">
            <div class="mr-1">
                <router-link class="btn btn-outline-dark border-0 rounded-lg"
                             v-bind:to="`/reviews`">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
            </div>
            <div class="flex-grow-1">
                <h3 class="font-weight-bolder text-right mb-0">
                    Review Details
                </h3>
                <p class="text-right mb-0">
                    <small>created</small> <strong>{{ createdAt }}</strong>
                </p>
            </div>
        </div>
        <div class="flex-grow-1 overflow-auto">
            <h1 class="position-sticky section-heading h4 font-weight-bolder px-3 py-1 mb-0">
                Basics
            </h1>
            <div class="px-3 mb-4">
                <review-reviewer class="mb-3"
                                 v-model="createdBy">
                </review-reviewer>
                <review-organizer class="mb-3"
                                  v-model="organizer">
                </review-organizer>
                <review-activity class="mb-3"
                                 v-bind:organizer="organizer"
                                 v-model="activity">
                </review-activity>
            </div>

            <h1 class="position-sticky section-heading h4 font-weight-bolder px-3 py-1 mb-0">
                Feedback
            </h1>
            <div class="px-3 mb-4">
                <form v-on:submit.stop.prevent="updateFeedback">
                    <b-form-group class="mb-2">
                        <b-form-textarea class="bg-light rounded-xl shadow-sm"
                                         rows="5"
                                         v-model="feedback">
                        </b-form-textarea>
                    </b-form-group>
                    <div class="text-right">
                        <button class="btn btn-sm btn-light border shadow-sm rounded-pill"
                                type="submit">
                            💾 Save
                        </button>
                    </div>
                </form>
            </div>

            <h1 class="position-sticky section-heading h4 font-weight-bolder px-3 py-1 mb-0">
                Ratings
            </h1>
            <div class="px-3 mb-4">
                <div class="d-flex align-items-end mb-3">
                    <div class="text-secondary">
                        <small>Impression</small>
                    </div>
                    <div class="flex-grow-1">
                        <div class="d-flex flex-nowrap justify-content-end">
                            <b-form-select class="ratings-select font-weight-bold bg-light rounded-pill shadow-sm mr-2"
                                           v-model="impression">
                                <option v-bind:value="1">1</option>
                                <option v-bind:value="2">2</option>
                                <option v-bind:value="3">3</option>
                                <option v-bind:value="4">4</option>
                                <option v-bind:value="5">5</option>
                            </b-form-select>
                            <h3 class="mb-0">
                                <small>/ 5</small>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-end mb-3">
                    <div class="text-secondary">
                        CP Ratio
                    </div>
                    <div class="flex-grow-1">
                        <div class="d-flex flex-nowrap justify-content-end">
                            <b-form-select class="ratings-select font-weight-bold bg-light rounded-pill shadow-sm mr-2"
                                           v-model="cpRatio">
                                <option v-bind:value="1">1</option>
                                <option v-bind:value="2">2</option>
                                <option v-bind:value="3">3</option>
                                <option v-bind:value="4">4</option>
                                <option v-bind:value="5">5</option>
                            </b-form-select>
                            <h3 class="mb-0">
                                <small>/ 5</small>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-end mb-3">
                    <div class="text-secondary">
                        Joyfulness
                    </div>
                    <div class="flex-grow-1">
                        <div class="d-flex flex-nowrap justify-content-end">
                            <b-form-select class="ratings-select font-weight-bold bg-light rounded-pill shadow-sm mr-2"
                                           v-model="interesting">
                                <option v-bind:value="1">1</option>
                                <option v-bind:value="2">2</option>
                                <option v-bind:value="3">3</option>
                                <option v-bind:value="4">4</option>
                                <option v-bind:value="5">5</option>
                            </b-form-select>
                            <h3 class="mb-0">
                                <small>/ 5</small>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-sticky actions d-flex p-3">
            <button class="btn btn-block btn-success rounded-pill mb-0 mr-1"
                    v-if="isApproved === false || isApproved === null"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click="approve">
                👍 Approve
            </button>
            <button class="btn btn-block btn-danger rounded-pill mt-0 ml-1"
                    v-if="isApproved === true || isApproved === null"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click="reject">
                👎 Reject
            </button>
        </div>
    </side-panel>
</template>

<script>
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { BFormGroup, BFormTextarea, BFormSelect, } from "bootstrap-vue";
export default {
    name: "ReviewDetails",
    components: {
        BFormGroup, BFormTextarea, BFormSelect,
        SidePanel: () => import("../SidePanel"),
        ReviewReviewer: () => import("@/components/Reviews/ReviewDetails/Reviewer"),
        ReviewOrganizer: () => import("@/components/Reviews/ReviewDetails/Organizer"),
        ReviewActivity: () => import("@/components/Reviews/ReviewDetails/Activity"),
    },
    props: {
        reviewId: {
            type: String,
        },
    },
    data () {
        return {
            isLoading: false,

            feedbackTemp: null,
        };
    },
    computed: {
        users () {
            return this.$store.getters["users/items"];
        },
        review () {
            return this.$store.getters["evaluation/itemById"](this.reviewId);
        },
        isApproved () {
            return this.review?.isApproved ?? null;
        },
        createdAt () {
            return this.review?.createdAt ?
                formatDistanceToNow(new Date(this.review.createdAt), { "addSuffix": true, }) :
                "Not available";
        },
        answers () {
            return this.review?.answers ?? {};
        },
        organizer: {
            get () {
                return this.answers?.organizer ?? "";
            },
            set (val) {
                const temp = {
                    "_id": this.reviewId,
                    "answers": {
                        ...this.answers,
                        "organizer": val,
                    },
                };
                this.update(temp);
            },
        },
        activity: {
            get () {
                return this.answers?.activityAttended ?? "";
            },
            set (val) {
                const temp = {
                    "_id": this.reviewId,
                    "answers": {
                        ...this.answers,
                        "activityAttended": val,
                    },
                };
                this.update(temp);
            },
        },
        createdBy: {
            get () {
                return this.review?.createdBy ?? "";
            },
            set (val) {
                const temp = {
                    "_id": this.reviewId,
                    "createdBy": val,
                };
                this.update(temp);
            }
        },
        feedback: {
            get () {
                if (this.feedbackTemp !== null) {
                    return this.feedbackTemp;
                }
                return this.answers?.feedback;
            },
            set (val) {
                this.feedbackTemp = val;
            }
        },
        impression: {
            get () {
                return this.answers?.impression;
            },
            set (val) {
                const temp = {
                    "_id": this.reviewId,
                    "answers": {
                        ...this.answers,
                        "impression": val.toString(),
                    },
                };
                this.update(temp);
            }
        },
        cpRatio: {
            get () {
                return this.answers?.cpRatio;
            },
            set (val) {
                const temp = {
                    "_id": this.reviewId,
                    "answers": {
                        ...this.answers,
                        "cpRatio": val.toString(),
                    },
                };
                this.update(temp);
            }
        },
        interesting: {
            get () {
                return this.answers?.interesting;
            },
            set (val) {
                const temp = {
                    "_id": this.reviewId,
                    "answers": {
                        ...this.answers,
                        "interesting": val.toString(),
                    },
                };
                this.update(temp);
            }
        },
    },
    methods: {
        async update (update) {
            try {
                this.isLoading = true;
                await this.$store.dispatch("evaluation/edit", update);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async updateFeedback () {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "evaluation/edit",
                    {
                        "_id": this.reviewId,
                        "answers": {
                            ...this.answers,
                            "feedback": this.feedback,
                        },
                    }
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async approve () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("evaluation/approve", this.reviewId);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async reject () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("evaluation/reject", this.reviewId);
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.review-details {

}

.ratings-select {
    width: 100px;
}

.section-heading {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
}

.actions {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2.5px);
    z-index: 99;
}
</style>

<style lang="scss">
.review-details-selector {
    .vs__dropdown-toggle {
        height: 100%;
        border: none;
    }
}
</style>
